import React from 'react'
import { PrismicPlacemeFunctionalityDataBodyTitleDescriptionSlice } from './TitleDescriptionTypes'
import { RichText } from 'prismic-reactjs'
import styled, { css } from 'styled-components'
import { colors, productColors } from 'shared/constants'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { InputMaybe, Scalars } from 'types/declarations'


const Section = styled.section<{ backgroundColor?: InputMaybe<Scalars['String']> }>(({ backgroundColor }) => css`
  background-color: ${backgroundColor ? backgroundColor : colors.background};
`)

const Wrapper = styled.div<{ higher?: InputMaybe<Scalars['Boolean']> }>(({ higher }) => {
  const { tablet, mobile } = useMediaQuery();
  
  return css`
    margin: 0 auto;
    max-width: 1440px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${higher ? '78px 84px;' : '78px 84px 0;'};

    ${tablet && 'padding: 40px 25px'}
    ${mobile && 'padding: 20px 15px'}
  `
})

interface CommonTextProps {
  fullWidthText?: InputMaybe<Scalars['Boolean']>
}

const StyledTitle = styled.h1<CommonTextProps>(({ fullWidthText }) => {
  const { tablet, mobile } = useMediaQuery()

  return css`
    font-weight: 700;
    font-size: ${tablet ? '2.375rem' : mobile ? '1.75rem' : '2.75rem'};
    line-height: ${tablet ? '2.875rem' : mobile ? '2.125rem' : '3.375rem'};
    ${!fullWidthText && css`max-width: 900px;`}
    max-width: 900px;
    margin-bottom: 2rem;
    text-align: center;
    color: ${colors.darkBlue};
  `
})

const StyledDescription = styled.div<CommonTextProps>(({ fullWidthText }) => {
  const { mobile } = useMediaQuery()

  return css`
    ${!fullWidthText && css`max-width: 940px;`}
    text-align: center;

    p {
      font-size: ${mobile ? '1.25rem' : '1.5rem'};
      font-weight: 400;
      line-height: ${mobile ? '1.75rem' : '2rem'};
      margin: 0;
      text-align: center;
      color: ${colors.darkBlue};

      em {
        background-color: ${productColors.violet.light};
        font-style: normal;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 3rem;
      color: ${colors.darkBlue};
    }
  `
})

const TitleDescriptionSlice: React.FC<{ slice: PrismicPlacemeFunctionalityDataBodyTitleDescriptionSlice }> = ({ slice }) => {
  const fullWidth = slice?.primary?.full_width_text

  return (
    <Section backgroundColor={slice?.primary?.background_color}>
      <Wrapper higher={slice?.primary?.higher_section}>
        <StyledTitle fullWidthText={fullWidth}>{RichText.asText(slice?.primary?.title?.richText)}</StyledTitle>
        <StyledDescription fullWidthText={fullWidth}>{RichText.render(slice?.primary?.description?.richText)}</StyledDescription>
      </Wrapper>
    </Section>
  )
}

export default TitleDescriptionSlice
